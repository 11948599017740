import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Stack
} from '@mui/material';
import { stripePromise } from '../config/stripe';

const SubscriptionModal = ({ open, onClose, userId, currentConversation }) => {
  const handleSubscribe = async () => {
    if (currentConversation && currentConversation.length > 0) {
      localStorage.setItem('pendingConversation', JSON.stringify(currentConversation));
    }
    
    const stripe = await stripePromise;
    
    const response = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });
    
    const session = await response.json();
    
    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });
    
    if (result.error) {
      console.error(result.error);
      localStorage.removeItem('pendingConversation');
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>Upgrade to Premium</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 1 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, mb: 2 }}>
            You get:
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            • Unlimited messages
            • Exclusive access to new beta features
          </Typography>
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography 
              variant="body1" 
              sx={{ 
                textDecoration: 'line-through',
                color: 'text.secondary',
              }}
            >
              $19.99/month
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: 'accent.main',
                fontWeight: 'medium',
              }}
            >
              $9.99/month
            </Typography>
            <Typography 
              variant="caption"
              sx={{ 
                color: 'accent.main',
                fontWeight: 'medium'
              }}
            >
              Limited time offer when billed annually
            </Typography>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button onClick={onClose} sx={{ color: 'text.secondary' }}>
          Cancel
        </Button>
        <Button 
          onClick={handleSubscribe} 
          variant="contained" 
          sx={{ 
            bgcolor: 'accent.main',
            '&:hover': {
              bgcolor: 'accent.dark'
            }
          }}
        >
          Subscribe Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionModal;
