import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Logo from './Logo';
import { Link } from 'react-router-dom';

function AppTitle() {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                <Logo width="100" height="100" style={{ marginRight: '10px' }} />
                <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'text.primary', fontWeight: 400 }}>
                    calmind.ai
                </Typography>
            </Link>
        </Box>
    );
}

export default AppTitle;