import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfigs = {
  production: {
    apiKey: "AIzaSyCDY8c023opmUy9D81HF1HCHR8y2AT7mg8",
    authDomain: "calmind.ai",
    projectId: "mhchatbot-b18ca",
    storageBucket: "mhchatbot-b18ca.appspot.com",
    messagingSenderId: "963805274379",
    appId: "1:963805274379:web:2131f1015177a7f836c20e",
    measurementId: "G-CCJF15H1SX"
  },
  staging: {
    apiKey: "AIzaSyD0Dg2nKU5PDgnqGjKVO3O9X49s-CFb8sI",
    authDomain: "calm-stage-75b84.firebaseapp.com",
    projectId: "calm-stage-75b84",
    storageBucket: "calm-stage-75b84.firebasestorage.app",
    messagingSenderId: "302658366846",
    appId: "1:302658366846:web:ec13838467c72a59e5572f"
  }
};

const environment = process.env.REACT_APP_ENVIRONMENT || 'production';
const firebaseConfig = firebaseConfigs[environment];

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { db, auth, googleProvider };