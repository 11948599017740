import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicyPage = () => {
  const [policy, setPolicy] = useState('');

  useEffect(() => {
    fetch('/privacy-policy.md')
      .then(response => response.text())
      .then(text => setPolicy(text));
  }, []);

  return (
    <Box className={styles.container}>
      <Typography variant="h4" component="h1" gutterBottom align="center" className={styles.heading}>
        Privacy Policy
      </Typography>
      <ReactMarkdown className={styles.markdown}>{policy}</ReactMarkdown>
    </Box>
  );
};

export default PrivacyPolicyPage;

