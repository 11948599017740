import React, { useEffect, useState } from "react";
import "./App.css";
import Onboarding from "./components/Onboarding";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase-config"
import Container from '@mui/material/Container';
import Box from '@mui/material/Box'; 
import AppTitle from "./components/AppTitle"; 
import LandingPage from "./components/LandingPage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from "@mui/material/colors";
import { Route, Routes } from 'react-router-dom';
import { ChatPage } from "./components/ChatPage";
import TermsAndConditionsPage from './components/TermsAndConditionsPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import FooterLinks from './components/FooterLinks';
import ThemeToggle from './components/ThemeToggle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import UserProfileMenu from './components/UserProfileMenu';
import Avatar from '@mui/material/Avatar';
import ProtectedRoute from './components/ProtectedRoute';
import CollapsibleHeader from './components/CollapsibleHeader';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#66A5AD',
    },
    secondary: {
      main: '#9A8FBA',
    },
    background: {
      default: "#121F2A",
      paper: "#1A2936",
    },
    text: {
      primary: '#E0E0E0',
      secondary: '#B0B0B0'
    },
    accent: {
      main: '#FFD485',
    }
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  h6: {
    fontFamily: 'Quicksand'
  }
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4A8C94',
    },
    secondary: {
      main: '#F5D0C5',
    },
    accent: {
      main: '#FFBF69',
    },
    background: {
      default: "#FFF5E6",
      paper: "#FFFFFF",
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#7D7D7D'
    },
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  h6: {
    fontFamily: 'Quicksand'
  }
});

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    return savedTheme !== null ? JSON.parse(savedTheme) : true;
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
    });
    
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('isDarkMode', JSON.stringify(newTheme));
  };

  useEffect(() => {
    const rootStyle = document.documentElement.style;
    if (isDarkMode) {
      rootStyle.setProperty('--text', '#E0E0E0');
      rootStyle.setProperty('--background', '#121F2A');
      rootStyle.setProperty('--primary', '#66A5AD');
      rootStyle.setProperty('--secondary', '#9A8FBA');
      rootStyle.setProperty('--accent', '#FFD485');
    } else {
      rootStyle.setProperty('--text', '#4A4A4A');
      rootStyle.setProperty('--background', '#FFF5E6');
      rootStyle.setProperty('--primary', '#4A8C94');
      rootStyle.setProperty('--secondary', '#F5D0C5');
      rootStyle.setProperty('--accent', '#FFBF69');
    }
  }, [isDarkMode]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    if (savedTheme !== null) {
      setIsDarkMode(JSON.parse(savedTheme));
    }
  }, []);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: 'background.default' }}>
        <Box sx={{ 
          display: { xs: 'none', sm: 'flex' }, 
          justifyContent: 'space-between', 
          alignItems: 'flex-start', 
          padding: '8px 16px', 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          zIndex: { xs: 1000, sm: 998 }, 
          bgcolor: 'background.default',
        }}>
          <AppTitle />
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
            {user && (
              <>
                <IconButton 
                  onClick={handleProfileClick}
                  sx={{ color: 'text.primary', padding: '4px' }}
                >
                  <Avatar 
                    src={user?.photoURL}
                    alt={user?.displayName}
                    sx={{ width: 32, height: 32 }}
                  />
                </IconButton>
                <UserProfileMenu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  user={user}
                />
              </>
            )}
          </Box>
        </Box>
        <CollapsibleHeader 
          user={user} 
          isDarkMode={isDarkMode} 
          toggleTheme={toggleTheme} 
        />
        <Container disableGutters sx={{ flexGrow: 1, mt: 0 }}>
          <Routes>
            <Route path="/" element={<LandingPage user={user} isDarkMode={isDarkMode} />} />
            <Route path="/chat-page" element={
                <ProtectedRoute>
                    <ChatPage user={user} isDarkMode={isDarkMode} />
                </ProtectedRoute>
            } />
            <Route path="/terms" element={<TermsAndConditionsPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
          </Routes>
          <FooterLinks />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;