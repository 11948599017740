import React from "react";

const Onboarding = () => {
  return (
    <div>
      <h2>Welcome to the Mental Health Chatbot!</h2>
      <p>
        This chatbot aims to provide immediate relief for individuals
        experiencing mental health issues, such as anxiety or depression.
      </p>
      <p>
        To get started, simply enter your concern or question and our chatbot will provide guidance and support. If necessary, it will also recommend local mental health resources.
      </p>
      <button>Start Chatting</button>
    </div>
  );
};

export default Onboarding;