import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const FooterLinks = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 10,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        px: 4,
        zIndex: 1000,
        '@media (max-width: 600px)': {
          position: 'static',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 2,
          backgroundColor: 'background.default',
          padding: 1,
          width: '100%',
        },
      }}
    >
      {/* Left side links */}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Typography variant="caption">
          <Link href="mailto:support@calmind.ai" color="text.secondary" underline="hover">
            Support
          </Link>
        </Typography>
        <Typography variant="caption">
          <Link href="https://forms.gle/sHMwSbCTEYe9Jh3S7" target="_blank" rel="noopener noreferrer" color="text.secondary" underline="hover">
            Feedback
          </Link>
        </Typography>
      </Box>

      {/* Right side links */}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Typography variant="caption">
          <Link href="/terms" color="text.secondary" underline="hover">
            Terms
          </Link>
        </Typography>
        <Typography variant="caption">
          <Link href="/privacy" color="text.secondary" underline="hover">
            Privacy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterLinks;
