import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import { auth } from '../firebase-config';

const UserAvatar = () => {
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User photo URL:', user.photoURL);
        setPhotoURL(user.photoURL);
      } else {
        setPhotoURL(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Avatar src={photoURL} alt="User">
      {!photoURL && 'U'}
    </Avatar>
  );
};

export default UserAvatar;