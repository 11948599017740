import React, { useState, useEffect } from 'react';
import { Button, IconButton, TextField } from "@mui/material";
import styles from "./LandingPage.module.css";
import SendIcon from "@mui/icons-material/Send"
import Box from '@mui/material/Box';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { auth } from '../firebase-config';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import TermsAndConditionsPopup from './TermsAndConditionsPopup';
import { doc, setDoc, serverTimestamp, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../firebase-config';

export default function LandingPage({user, isDarkMode}) {
    const [firstInput, setInput] = useState("");
    const [text, setText] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [showTerms, setShowTerms] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleRedirects = () => {
            // Check for Stripe success parameters
            const params = new URLSearchParams(window.location.search);
            const success = params.get('success') === 'true';
            const sessionId = params.get('session_id');

            if (success && sessionId && user) {
                navigate({
                    pathname: 'chat-page',
                    search: `?success=true&session_id=${sessionId}`
                });
                return;
            }

            // Check for stored redirect
            if (user) {
                const redirectPath = sessionStorage.getItem('redirectAfterAuth');
                if (redirectPath) {
                    sessionStorage.removeItem('redirectAfterAuth');
                    navigate(redirectPath);
                }
            }
        };

        handleRedirects();
    }, [user, navigate]);

    useEffect(() => {
        if (firstInput.trim() && !showTerms) { 
            navigate({
                pathname: 'chat-page',
                search: createSearchParams({
                    first: firstInput
                }).toString()
            });
        }
    }, [firstInput, navigate, showTerms]);

    const handleInputChange = (event) => {
        setText(event.target.value);
        setBtnDisabled(!event.target.value);  
    };

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log('User signed in:', user);
            
            // Create or update user profile in Firestore
            const userRef = doc(db, "Users", user.uid);
            const userDoc = await getDoc(userRef);
            
            if (!userDoc.exists()) {
                // Only set initial values for new users
                await setDoc(userRef, {
                    email: user.email,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    subscriptionStatus: "free",
                    interactionCount: 0,
                    createdAt: serverTimestamp(),
                    freeMessagesRemaining: 10,
                });
            } else {
                // For existing users, only update their basic profile info
                await updateDoc(userRef, {
                    email: user.email,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && text.trim()) {
            event.preventDefault();
            setShowTerms(true);
        }
    };

    const handleEmotionButtonClick = (emotion, event) => {
        event.preventDefault();
        const templateMessage = `I am feeling ${emotion}.`;
        setText(templateMessage);
        setShowTerms(true);
        // Store both the flag and the message
        localStorage.setItem('emotionButtonData', JSON.stringify({
            isEmotionButton: true,
            message: templateMessage
        }));
    };

    const handleSendButtonClick = () => {
        if (text.trim()) {
            setShowTerms(true);
        }
    };

    const handleAcceptTerms = () => {
        setShowTerms(false);
        setInput(text);
        // Clear the flag when navigating away
        localStorage.removeItem('isEmotionButtonMessage');
    };

    const renderAuthenticatedUI = () => (
        <>
            <div className={styles.middle}>
                {["depressed", "lonely", "sad", "anxious", "guilty", "angry"].map((emotion, index) => (
                    <Button 
                        key={index}
                        sx={{ 
                            width: 320, 
                            borderRadius: '20px', 
                            color: 'text.primary', 
                            borderColor: 'secondary.main',
                            '&:hover': {
                                backgroundColor: 'secondary.main',
                                borderColor: 'secondary.main',
                            }
                        }} 
                        variant="outlined" 
                        onClick={(event) => handleEmotionButtonClick(emotion, event)}
                    >
                        {emotion}
                    </Button>
                ))}
            </div>
            <div className={styles.bottom}>
                <Box className={styles.messagetextareaframe} sx={{
                    backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(245, 209, 199, 0.35)',
                }}>
                    <TextField
                        className={styles.typeYourMessage}
                        placeholder="Say something..."
                        color="primary"
                        variant="standard"
                        multiline
                        rows={1}
                        maxRows={1}
                        fullWidth
                        InputProps={{ 
                            disableUnderline: true, 
                            endAdornment: (
                                <IconButton
                                    color='primary'
                                    disabled={btnDisabled}
                                    onClick={handleSendButtonClick}
                                    sx={{ 
                                        color: btnDisabled ? 'rgba(255, 255, 255, 0.3)' : 'accent.main',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 212, 133, 0.1)',
                                        },
                                    }}
                                >
                                    <SendIcon />
                                </IconButton>
                            ),
                        }}
                        InputLabelProps={{ shrink: false }}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        value={text}
                    />
                </Box>
            </div>
        </>
    );

    const renderUnauthenticatedUI = () => (
        <div className={`${styles.middle} ${styles.googleSignInButton}`}>
            <button 
                onClick={googleSignIn} 
                style={{
                    background: `url('/web_dark_rd_ctn@2x.png') no-repeat center center`,
                    backgroundSize: 'contain',
                    width: '283.5px', 
                    height: '80px', 
                    border: 'none',
                    cursor: 'pointer'
                }}
                aria-label="Sign in with Google"
            >
                {/* Set to empty if your image contains all necessary text, otherwise you can place text here */}
            </button>
        </div>
    );

    return (
        <div className={`${styles.landingpage} ${showTerms ? styles.blurred : ''}`}>
            <div className={styles.top}>
            </div>
            <div>
                <h1>
                    how are you feeling today?
                </h1>
            </div>
            {user ? renderAuthenticatedUI() : renderUnauthenticatedUI()}
            <TermsAndConditionsPopup open={showTerms} onAccept={handleAcceptTerms} />
        </div>
    );
}