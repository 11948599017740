import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Menu,
    MenuItem,
    Box,
    Typography,
    Avatar,
    IconButton,
    Divider,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { doc, onSnapshot } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51QHaWl01ij5HqBYY8ABYsRpyi223iFCRBJD2F9LWUxAwrn7zCFhF4uReykJoiKYGSnhlniRwU7dLnj9llu12fgHk00ikMPjUfL');

const UserProfileMenu = ({ anchorEl, open, onClose, user }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        subscriptionStatus: 'free',
        freeMessagesRemaining: 10
    });
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (!user?.uid) return;

        const unsubscribe = onSnapshot(doc(db, "Users", user.uid), (doc) => {
            if (doc.exists()) {
                setUserData({
                    subscriptionStatus: doc.data().subscriptionStatus || 'free',
                    freeMessagesRemaining: doc.data().freeMessagesRemaining || 0,
                    customerId: doc.data().customerId
                });
            }
        });

        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        const handleNavigationChange = () => {
            setIsProcessing(false);
            localStorage.removeItem('pendingConversation');
        };

        window.addEventListener('popstate', handleNavigationChange);
        window.addEventListener('beforeunload', handleNavigationChange);

        return () => {
            window.removeEventListener('popstate', handleNavigationChange);
            window.removeEventListener('beforeunload', handleNavigationChange);
            handleNavigationChange();
        };
    }, []);

    const handleUpgradeClick = async () => {
        try {
            const currentPath = window.location.pathname;
            if (currentPath.includes('chat-page')) {
                const currentConversation = JSON.parse(localStorage.getItem('currentConversation') || '[]');
                if (currentConversation.length > 0) {
                    localStorage.setItem('pendingConversation', JSON.stringify(currentConversation));
                }
            }

            setIsProcessing(true);
            const response = await fetch('/api/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.uid,
                }),
            });

            const session = await response.json();
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: session.sessionId });

            if (error) {
                console.error('Stripe checkout error:', error);
                localStorage.removeItem('pendingConversation');
            }
        } catch (error) {
            console.error('Error initiating checkout:', error);
            localStorage.removeItem('pendingConversation');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            onClose();
            navigate('/');
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const handleManageSubscription = async () => {
        try {
            if (!userData.customerId) {
                console.error('No customer ID found');
                return;
            }
            
            setIsProcessing(true);
            const response = await fetch('/api/create-portal-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId: userData.customerId,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create portal session');
            }

            const { url } = await response.json();
            if (!url) {
                throw new Error('No portal URL received');
            }
            
            window.location.href = url;
        } catch (error) {
            console.error('Error accessing customer portal:', error);
            // You might want to show an error message to the user here
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            PaperProps={{
                elevation: 3,
                sx: {
                    width: 320,
                    mt: 1.5,
                    padding: 2,
                    borderRadius: 2,
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Box sx={{ position: 'relative', pb: 2 }}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: -8,
                        top: -8,
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar 
                        src={user?.photoURL}
                        alt={user?.displayName}
                        sx={{ width: 60, height: 60, mr: 2 }}
                    />
                    <Box>
                        <Typography variant="subtitle1">{user?.displayName}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {user?.email}
                        </Typography>
                    </Box>
                </Box>

                <Divider />

                <Box sx={{ py: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Account Status
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        {userData.subscriptionStatus === 'premium' ? 'Premium Plan' : 'Free Plan'}
                    </Typography>
                    {userData.subscriptionStatus === 'free' && (
                        <>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                {userData.freeMessagesRemaining} conversations remaining
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleUpgradeClick}
                                disabled={isProcessing}
                                sx={{
                                    borderRadius: 1,
                                    textTransform: 'none',
                                    mb: 2
                                }}
                            >
                                {isProcessing ? 'Processing...' : 'Upgrade to Premium'}
                            </Button>
                        </>
                    )}
                    {userData.subscriptionStatus === 'premium' && (
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={handleManageSubscription}
                            disabled={isProcessing}
                            sx={{
                                borderRadius: 1,
                                textTransform: 'none',
                                mb: 2
                            }}
                        >
                            {isProcessing ? 'Processing...' : 'Manage Subscription'}
                        </Button>
                    )}
                    <Button
                        fullWidth
                        variant="outlined"
                        color="inherit"
                        onClick={handleSignOut}
                        sx={{
                            borderRadius: 1,
                            textTransform: 'none'
                        }}
                    >
                        Sign Out
                    </Button>
                </Box>
            </Box>
        </Menu>
    );
};

export default UserProfileMenu;
