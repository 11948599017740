import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 800,
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '85vh',
  overflowY: 'auto',
};

const TermsAndConditionsPopup = ({ open, onAccept }) => {
  const [summary, setSummary] = useState('');

  useEffect(() => {
    fetch('/terms-and-conditions-summary.md')
      .then(response => response.text())
      .then(text => setSummary(text));
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          Summary of Terms and Conditions
        </Typography>
        <ReactMarkdown components={{
          h2: ({ node, ...props }) => <Typography variant="body1" {...props} />,
          p: ({ node, ...props }) => <Typography variant="body2" {...props} sx={{ mb: 1 }} />,
          li: ({ node, ...props }) => <Typography variant="body2" component="li" {...props} sx={{ mb: 0.5 }} />,
        }}>
          {summary}
        </ReactMarkdown>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            Read full Terms and Conditions
          </a>
          <Button onClick={onAccept} variant="contained" color="primary">
            Accept
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsAndConditionsPopup;
