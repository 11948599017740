import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import styles from './TermsAndConditions.module.css';

const TermsAndConditionsPage = () => {
  const [terms, setTerms] = useState('');

  useEffect(() => {
    fetch('/terms-and-conditions.md')
      .then(response => response.text())
      .then(text => setTerms(text));
  }, []);

  return (
    <Box className={styles.container}>
      <Typography variant="h4" component="h1" gutterBottom align="center" className={styles.heading}>
        Terms and Conditions
      </Typography>
      <ReactMarkdown className={styles.markdown}>{terms}</ReactMarkdown>
    </Box>
  );
};

export default TermsAndConditionsPage;
