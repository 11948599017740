import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { CircularProgress, Box } from '@mui/material';

const ProtectedRoute = ({ children }) => {
    const [authState, setAuthState] = useState('loading'); // 'loading' | 'authenticated' | 'unauthenticated'

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setAuthState(user ? 'authenticated' : 'unauthenticated');
        });

        return () => unsubscribe();
    }, []);

    if (authState === 'loading') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (authState === 'unauthenticated') {
        // Store the intended destination
        sessionStorage.setItem('redirectAfterAuth', window.location.pathname + window.location.search);
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute;
